
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  name: "General",

  setup() {
    const dataList = [
      {
        name: "dashboard",
        route: "/dashboards",
        permission: "type entities: index",
      },
      {
        name: "type_entities",
        route: "/type-entities",
        permission: "type entities: index",
      },
      {
        name: "address_type",
        route: "/address_type",
        permission: "addresses: index",
      },
      {
        name: "measures_unit",
        route: "/measures_unit",
        permission: "measure unit: index",
      },
      {
        name: "measures_type",
        route: "/measures_type",
        permission: "measures type: index",
      },
      {
        name: "measure_unit_type",
        route: "/measure_unit_type",
        permission: "measure unit type: index",
      },
      {
        name: "contacts_type",
        route: "/contacts_type",
        permission: "contacts type: index",
      },
      {
        name: "transport_order_status",
        route: "/transport_order_status",
        permission: "transport order status: index",
      },
      {
        name: "transport_order_type",
        route: "/transport_order_type",
        permission: "transport order type: index",
      },
      {
        name: "maritime_file_type",
        route: "/maritime_file_type",
        permission: "maritime file type: index",
      },
      {
        name: "routes_type",
        route: "/routes_type",
        permission: "routes type: index",
      },
      {
        name: "equipments",
        route: "/equipments",
        permission: "equipments: index",
      },
      {
        name: "equipments_type",
        route: "/equipments_type",
        permission: "equipments type: index",
      },
      {
        name: "equipments_subtype",
        route: "/equipments_subtype",
        permission: "equipments subtype: index",
      },
      {
        name: "sectors_type",
        route: "/sectors_type",
        permission: "sectors type: index",
      },
      {
        name: "maritime_file_operations",
        route: "/maritime_file_operations",
        permission: "maritime file operations: index",
      },
      {
        name: "maritime_services",
        route: "/maritime_services",
        permission: "maritime services: index",
      },
      {
        name: "ports",
        route: "/ports",
        permission: "ports: index",
      },
      {
        name: "media_type",
        route: "/media_type",
        permission: "media type: index",
      },
      {
        name: "location_type",
        route: "/location_type",
        permission: "location type: index",
      },
      {
        name: "goods_type",
        route: "/goods_type",
        permission: "goods type: index",
      },
      {
        name: "smpurposes",
        route: "/smpurposes",
        permission: "smpurposes: index",
      },
      {
        name: "smrequirements",
        route: "/smrequirements",
        permission: "smrequirements: index",
      },
      {
        name: "smoperation_type",
        route: "/smoperation_type",
        permission: "smoperation type: index",
      },
      {
        name: "docks",
        route: "/docks",
        permission: "docks: index",
      },
    ];
    const dataObject = ref({});
    const search = ref("");

    const onSearch = () => {
      dataObject.value = dataList.filter(
        (x) =>
          JSON.stringify(x)
            .toLowerCase()
            .indexOf(search.value.toLowerCase()) !== -1
      );
    };

    onMounted(() => {
      dataObject.value = dataList;
    });

    return {
      dataObject,
      dataList,
      search,
      onSearch,
    };
  },
});
